import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);

const routes = [
  { name: 'eventList', path: "/", component: () => import("../views/work/index.vue") }, // 入口页面
  { name: 'home', path: "/home", component: () => import("../views/home/index.vue") }, // 主页
  {
    path: "",
    component: () => import("@/layout/NavBar"), // 主框架
    children: [

      { name: 'notice', path: "/notice", component: () => import("../views/notice/index.vue") }, // 详情页（活动简介）
      {
        name: 'articlelist',
        path: "/article/list",
        component: () => import("../views/article/newsList.vue"),
      }, // 赛事动态（列表）
      {
        name: 'article',
        path: "/article",
        component: () => import("../views/article/news.vue"),
      }, // 赛事动态（详情）
      {
        name: 'eventNews',
        path: "/eventNews",
        component: () => import("../views/article/eventNews.vue"),
      }, // 赛事新闻（详情）
      {
        name: 'donateAbout',
        path: "/donateAbout",
        component: () => import("../views/donateAbout/index.vue"),
      }, // 列表页（捐款详情）
      {
        name: 'useOfDonations',
        path: "/useOfDonations",
        component: () => import("../views/donateAbout/useOfDonations.vue"),
      }, // 列表页（捐款详情）

      {
        name: 'teamlist',
        path: "/team/list",
        component: () => import("../views/team/teamList.vue"),
      }, // 全部队伍列表

      /*  个人中心 */
      {

        path: "/personal",
        component: () => import("@/layout/personal.vue"), // 个人中心 导航
        children: [
          {
            name: "basicInfo",
            path: "/personal/basicInfo",
            component: () => import("@/views/personal/basicInfo.vue"),
          }, // 个人基本信息
          {
            name: "messageBox",
            path: "/personal/messageBox",
            component: () => import("@/views/personal/notification/inbox.vue"),
          }, // 信箱
          {
            name: "myDonation",
            path: "/personal/myDonation",
            component: () => import("@/views/personal/donate/myDonation.vue"),
          }, // 我的捐赠
          {
            name: "invitation",
            path: "/personal/invitation",
            component: () =>
              import("@/views/personal/donate/inviteoDnation.vue"),
          }, // 捐赠邀请
          {
            name: "completionCertificate",
            path: "/personal/completionCertificate",
            component: () =>
              import("@/views/personal/certificate/completionCertificate.vue"),
          }, // 完赛证书
          {
            name: 'donationCertificate',
            path: "/personal/donationCertificate",
            component: () =>
              import("@/views/personal/certificate/donationCertificate.vue"),
          }, // 捐赠证书
          {
            path: "/personal/myTeam/teamManage",
            component: () => import("@/views/personal/myTeam/teamManage.vue"),
          }, // 我的队伍管理
          {
            path: "/personal/myDonate",
            component: () => import("@/views/personal/donate/myDonation.vue"),
          }, // 我的捐款
          {
            path: "/personal/inviteoDnation",
            component: () =>
              import("@/views/personal/donate/inviteoDnation.vue"),
          }, // 邀请捐赠
          {
            path: "/personal/volunteer",
            component: () => import("@/views/personal/volunteer.vue"),
          }, // 志愿者
          {
            path: "/personal/registrationRecord",
            component: () =>
              import("@/views/personal/donate/registrationRecord.vue"),
          }, // 报名费记录
        ],
      },
      {
        path: "/infoChange",
        component: () => import("../views/infoChange/index.vue"),
      }, // 修改信息
      /*  空白页 废止页 */
      {
        path: "/application",
        component: () => import("../views/application/index.vue"),
      }, // 贾 详情页
      { path: "*", redirect: "/work" },
    ],
  },
];
const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});
router.onError((error) => {
  // Custom error handling logic
  console.error('Router Error:', error);
});

export default router;
