/** 多语言 - 英语 */
export default {
  people: "people",
  team: "team",
  group: "Group",
  yuan: "Yuan",
  day: "day",
  Donate: "Donation",
  home_page: "home page",
  login: "login",
  sex: "gender",
  phone: "telephone",
  blood: "blood type",
  birth: "Date of birth",
  email: "E-mail",
  clothingSize: "Clothing size",
  name: "Name",
  nick_name: "Nickname",
  haomabu:'Contestant number',
  identity:'Identity',
  raise:'Fundraising amount',
  ckdw:'View Teams',
  jzzl:'Donation assistance',
  consult:'Consult',
  reset:'Reset',
  lxwm:'Contact us',
  age: "Age",
  address: "Address",
  avatar: "Avatar",
  ordinal: "sordinal",
  time: "Time",
  amount: "Amount",
  Team: "Team",
  Group: "Group",
  team_name: "Team name",
  slogan: "slogan",
  team_leader: "Captain",
  send: "Send",
  volunteer: "Volunteer",
  in_progress: "In Progress",
  ended: "Ended",
  ranking_list_person: "Personal ranking list",
  ranking_list_group: "Team ranking list",
  ranking_list_team: "Group ranking list",
  next_step: "Next",
  logout: "Exit Login",
  participating_groups: "Team",
  registration_number: "Number of applicants",
  sign_up: "Sign Up",
  participation_way: "Participation Way",
  donation_amount: "Donation amount",
  notice: "活动须知",
  public_welfare_project: "Public welfare project",
  change_event: "Change event",
  more: "More",
  selected: "Current selection",
  event_dynamics: "Event dynamics",
  event_news: "Event News",
  event_schedule: "Events schedule",
  event_support: "event support",
  organization_unit: "Organization",
  want_to_donate: "I want to donate",
  activity_introduction: "Activity Introduction",
  entry_notice: "Instructions for registration",
  rules: "Active rules",
  route_map: "map",
  travel_instructions: "Instructions before travel",
  donation_notice: "Donation Instructions",
  FAQ: "FAQ",
  donation_guide: "Fundraising Guidelines",
  donation_objective: "Fundraising Target",
  contact_us: "Contact us",
  communication_group: "Event community",
  technical_support: "Technical Support",
  service_call: "Service phone",
  service_time: "Service time",
  message: "message",
  system_message: "System Message",
  team_message: "Team message",
  donation_records: "Donation Record",
  registratio_records: "Registration Record",
  registration_team: "Registration team",
  apply_invoice: "Request invoice",
  click_to_view: "Click to view",
  view_the_team: "View team",
  wonderful_moment: "Wonderful moment",
  join_us: "Join us",
  ID_type: "ID type",
  ID_number: "ID Number",
  basic_information: "Basic information",
  event_information: "Event information",
  use_of_donations: "the flow of money",
  emergency_contact_relation: "Emergency contact relationship",
  countdown: "Race Countdown",
  team_share_code: "Team sharing code",
  emergency_contact_number: "Emergency Contact Number",
  emergency_contact_name: "Emergency Contact name",
  // 提示语
  placeholder: "Please enter...",
  input_error: "incorrect input",
  successful_operation: "Operation successful",
  not_yet_open: "Not yet open",
  payment_method_prompt: "Please scan the code with your mobile phone to pa",
  succeed: "struggle hard! struggle! We will definitely succeed",
  // 队伍管理
  teamManagement: "Team management",
  operation: "Operation",
  my_medal: "My medal",
  total: "Total",
  pieces: "Pieces",
  communicationGroup: "Communication group",
  donationCode: "Donation Code",
  shareCode: "Share code",
  teamNum: "Number of team",
  fundraisingAmount: "Fundraising amount",
  achieved: "Achieved",
  about_us:"About us",
  personalCenter: "Personal Center",


  fundraisingList: "Fundraising List",
  ranking: "ranking",
  nickname: "nickname",
  activityRules: "Activity Rules",
  donationNotice: "Donation Notice",
  SMSLogin: "SMS login",
  excitingVideos: "Exciting Videos",
  newsCenter: "News Center",
  viewDetails: "View details",
};
