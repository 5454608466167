/** 多语言 - 中文 */
export default {
  people: "人",
  team: "队",
  yuan: "元",
  day: "天",
  Donate: "捐赠",
  home_page: "首页",
  login: "登录",
  sex: "性别",
  phone: "电话",
  blood: "血型",
  birth: "出生日期",
  email: "电子邮箱",
  clothingSize: "衣服尺码",
  name: "姓名",
  nick_name: "昵称",
  haomabu:'号码簿',
  identity:'身份',
  raise:'筹款金额',
  jzzl:'捐赠助力',
  ckdw:'查看队伍',
  consult:'查询',
  reset:'重置',
  lxwm:'联系我们',
  age: "年龄",
  address: "地址",
  avatar: "头像",
  ordinal: "序号",
  time: "时间",
  amount: "金额",
  Team: "队伍",
  Group: "团队",
  team_name: "队名",
  slogan: "口号",
  team_leader: "队长",
  send: "发送",
  volunteer: "志愿者",
  in_progress: "进行中",
  ended: "已结束",
  ranking_list_person: "个人榜",
  ranking_list_group: "团队榜",
  ranking_list_team: "队伍榜",
  next_step: "下一步",
  logout: "退出登录",
  participating_groups: "参赛团队",
  registration_number: "参加人数",
  sign_up: "报名通道",
  participation_way: "参与方式",
  registration_team: "参赛队伍",
  donation_amount: "捐款金额",
  notice: "活动须知",
  public_welfare_project: "公益项目",
  change_event: "更换赛事",
  more: "查看更多",
  selected: "当前选择",
  event_dynamics: "赛事动态",
  event_news: "赛事新闻",
  event_schedule: "赛事日程",
  event_support: "赛事支持",
  organization_unit: "组织机构",
  want_to_donate: "我要捐赠",
  activity_introduction: "活动简介",
  entry_notice: "报名须知",
  rules: "活动规则",
  route_map: "站点路线",
  travel_instructions: "行前须知",
  donation_notice: "捐赠须知",
  FAQ: "常见问题",
  donation_guide: "筹款指引",
  donation_objective: "筹款目标",
  contact_us: "联系我们",
  communication_group: "赛事社群",
  technical_support: "技术支持",
  service_call: "服务电话",
  service_time: "服务时间",
  message: "消息通知",
  system_message: "系统消息",
  team_message: "队伍消息",
  donation_records: "捐赠记录",
  registratio_records: "报名记录",
  apply_invoice: "申请发票",
  click_to_view: "点击查看",
  view_the_team: "查看队伍",
  wonderful_moment: "精彩瞬间",
  join_us: "加入我们",
  ID_type: "证件类型",
  ID_number: "证件号码",
  basic_information: "基本信息",
  event_information: "赛事信息",
  use_of_donations: "善款流向",
  emergency_contact_relation: "紧急联系人关系",
  countdown: "比赛倒计时",
  team_share_code: "队伍分享码",
  emergency_contact_number: "紧急联系电话",
  emergency_contact_name: "紧急联系人姓名",
  // 提示语
  placeholder: "请输入...",
  input_error: "输入有误",
  successful_operation: "操作成功",
  not_yet_open: "暂未开放",
  payment_method_prompt: "请使用手机扫码支付",
  succeed: "拼搏！奋斗！我们一定能成功",
  // 队伍管理
  teamManagement: "队伍管理",
  operation: "操作",
  my_medal: "我的勋章",
  total: "共",
  pieces: "枚",
  communicationGroup: "交流群",
  donationCode: "捐赠码",
  shareCode: "分享码",
  teamNum: "队伍人数",
  fundraisingAmount: "筹募金额",
  achieved: "已达成",
  about_us:"关于我们",
  personalCenter: "个人中心",

  fundraisingList: "筹款榜单",
  ranking: "排名",
  nickname: "昵称",
  activityRules: "活动规则",
  donationNotice: "捐赠须知",
  excitingVideos: "精彩视频",
  newsCenter: "新闻中心",
  viewDetails: "查看详情",
};
