import axios from "axios";
import local from "@/utils/local.js";
import qs from "qs";
import { Message } from "element-ui";

/*** 设置公共服务器路径 */
// axios.defaults.baseURL = "http://192.168.3.30:12340/ssi-auth";
 axios.defaults.baseURL = "https://ms.readygo.org.cn/ssi-auth";
// axios.defaults.baseURL = 'https://hqq.hongqiqiu.org.cn/ssi-auth';

/*** 添加请求拦截器 */
axios.interceptors.request.use(
  function (config) {
    // 发请求时，自动携带token
    let token = local.getToken();
    if (token) {
      config.headers["x-token-ssi-auth"] = token;
    }
    // 不同请求方式 所用type不同
    if (!config.headers["Content-Type"]) {
      if (config.method === "post" || config.method === "POST") {
        config.headers["Content-Type"] = "application/json; charset=utf-8";
        // 请求参数序列化
        // config.data = {raw : JSON.stringify(config.data)}
      }
    }
    return config;
  },
  function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);

/*** 添加响应拦截器 */
axios.interceptors.response.use(
  function (response) {
    if (response.data.code === 200) {
      return response.data;
    } else if (response.data.code === 401) {
      const options = {
        message: response.data.msg,
        duration: 3000,
      };
      Message.error(options);
      
    }
    else {
      const options = {
        message: response.data.msg,
        duration: 3000,
      };
      Message.error(options);
    }
    return response;
  },
  function (error) {
    // 超出 2xx 范围的状态码都会触发该函数  // 访问失败时执行
    // return Promise.reject(error);
  }
);

/*** 暴露增强后的axios */
export default axios;
