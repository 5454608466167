/** 多语言 - 韩语 */
export default {
  people: "명",
  team: "팀",
  yuan: "원",
  day: "하늘",
  Donate: "기부",
  home_page: "홈 페이지",
  login: "로그인",
  sex: "성별",
  phone: "전화",
  blood: "혈액형",
  birth: "생년일",
  email: "이메일",
  clothingSize: "옷 사이즈",
  name: "이름",
  nick_name: "별명",
  haomabu:'선수 번호',
  identity:'신분',
  raise:'모금액',
  ckdw:'팀 보기',
  jzzl:'조력을 기부하다',
  consult:'질의',
  reset:'재설정',
  lxwm:'연락처',
  age: "나이",
  address: "주소",
  avatar: "프로필 사진",
  ordinal: "일련번호",
  time: "시간",
  amount: "금액",
  Team: "팀",
  Group: "그룹",
  team_name: "팀 이름",
  slogan: "구호",
  team_leader: "대장",
  send: "보내기",
  volunteer: "자원봉사자",
  in_progress: "진행 중입니다",
  end: "종료",
  ranking_list_person: "개인 차트",
  ranking_list_group: "팀 차트",
  ranking_list_team: "팀 차트",
  next: "다음",
  logout: "로그인 종료",
  participating_groups: "참가 팀",
  registration_number: "참가인원",
  sign_up: "신청",
  participation_way: "참여 방식",
  donation_amount: "기부금 금액",
  notice: "알아야 할",
  public_welfare_project: "공익 프로젝트",
  change_event: "경기 변경",
  more: "더 보기",
  selected: "현재 선택",
  event_dynamics: "경기 동태",
  event_news: "경기 뉴스",
  event_schedule: "경기 일정",
  event_support: "경기 지원",
  organization_unit: "조직",
  want_to_donate: "기부",
  activity_introduction: "활동 소개",
  entry_notice: "신청 주의 사항",
  rules: "활동 규칙",
  route_map: "사이트 선형",
  travel_instructions: "출행 전 주의 사항",
  donation_notice: "기부 주의 사항",
  FAQ: "일반적인 문제",
  donation_guide: "모금 지침",
  donation_objective: "모금 목표",
  contact_us: "연락처",
  communication_group: "경기 그룹채팅",
  technical_support: "기술 지원",
  service_call: "서비스 전화",
  service_time: "서비스 시간",
  message: "메시지",
  system_message: "시스템 메시지",
  team_message: "팀 메시지",
  donation_records: "기부 기록",
  registrio_records: "등록 기록",
  apply_invoice: "송장 청구 ",
  click_to_view: "보기 클릭",
  view_the_team: "팀 보기",
  wonderful_moment: "멋진 순간",
  registration_team: "등록팀",
  // join_us: "加入我们",
  ID_type: "인증서 유형",
  ID_number: "ID 번호",
  basic_information: "기본 정보",
  event_information: "경기 정보",
  use_of_donations: "선금 흐름",
  emergency_contact_relation: "긴급 연락처",
  countdown: "경기 카운트다운",
  team_share_code: "초청 코드",
  emergency_contact_number: "긴급 연락처 휴대전화 번호",
  emergency_contact_name: "긴급 연락처 이름",
  // 提示语
  placeholder: "입력하십시오...",
  input_error: "입력 오류",
  successful_operation: "작업 성공",
  not_yet_open: "아직 개방되지 않았습니다",
  payment_method_prompt: "핸드폰으로 코드를 스캔하여 결제하세요",
  succeed: "분투!분투!우리는 반드시 성공할 수 있다",
  // 队伍管理
  teamManagement: "팀 관리",
  operation: "작업",
  my_medal: "나의 훈장",
  total: "총",
  pieces: "블록",
  communicationGroup: "교류군",
  donationCode: "기부 코드",
  shareCode: "공유 코드",
  teamNum: "팀 수",
  fundraisingAmount: "모금액",
  achieved: "달성",
  personalCenter: "개인 센터",
  fundraisingList: "모금 목록",
  ranking: "순위",
  nickname: "닉네임",
  activityRules: "활동 규칙",
  donationNotice: "기부 주의사항",
  excitingVideos: "하이라이트 비디오",
  newsCenter: "뉴스 센터",
  viewDetails: "자세히 보기",
};
